import { Router } from '@angular/router';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private authService: AuthService, 
        private route: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            const error = err.error.message || err.statusText;
            if (err.status === 500 || err.error.status === 500) {        
                if (err.error.message === "Session logged out. Please login again!" || err.message === "Session logged out. Please login again!") {
                    this.authService.logoutService();
                        // this.handle401Error(request, next);                                       
                } 
            }
            if(err.error.code === '1') {
                this.route.navigateByUrl('/change-password/'+err.error.userName);
            } else if(err.error.code === '2') {
                this.route.navigateByUrl('/change-password/'+err.error.userName);
            } else if(err.error.code === '3') {
                
            } else if(err.error.code === '4') {
                
            } else if(err.error.code === '5') {
                
            } else if(err.error.code === '6') {
                
            } else if(err.error.code === '7') {
                  Swal.fire('Enter Valid Password!', err.error.message, 'error')
            } else if(err.error.code === '8') {
                
            } else if(err.error.code === '9') {
                  Swal.fire('Enter Valid Username!', err.error.message, 'error')
            }
            return throwError(error);
        }));
    }
}
