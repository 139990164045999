import { Injectable } from '@angular/core';
import { HttpInterceptor,HttpRequest,HttpHandler,HttpEvent} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable()
export class ApiDelayInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const delayTime = 60000;
    return next.handle(request).pipe(
      timeout(delayTime),
      catchError((error) => {
        if (error.name === 'TimeoutError') {
           setTimeout(() => {
            Swal.close(); 
          Swal.fire('','The server took too long to respond. This may be due to a slow internet connection. Please try again.','error')
          }, 200);
        }
         else if(error.statusText === 'Unknown Error' ||  error.statusText === ''||  !error.statusText )  {
          setTimeout(() => {
            Swal.close(); 
          Swal.fire('','Please try again','error')
          }, 200);
        }
        return throwError(error);
      })
    );
  }
}
