import { Router } from '@angular/router';
import { EncryptService } from './encrypt.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
public currentUser: BehaviorSubject<any> = new BehaviorSubject<any>('');

constructor(private http: HttpClient, private encrypt: EncryptService, private router: Router) { 
  
}

public get currentUserValue(): any {
  let current_user = JSON.parse(localStorage.getItem('current_user_value') || '{}');
  return current_user;

  // if (this.currentUser) {
  //   return (this.currentUser.value);
  // }
}

loggedin:boolean = false
loginService(user: any) {
  if(this.loggedin){
    return
  }
  this.loggedin = true
  const data = { emailId: this.encrypt.generateEncryption(user.userName), password: this.encrypt.generateEncryption(user.password), loggedInPortal: environment.loginPortal };
  const headers = new HttpHeaders();

  headers.append('Content-Type', 'application/json;charset=UTF-8');
  const httpOptions = { headers };
  return this.http.post(environment.apiBaseUrl + '/user/provider-login', data, httpOptions).subscribe((user:any)=> {
      localStorage.setItem('current_user_value',JSON.stringify(user));
      localStorage.setItem('id',user.id)
      localStorage.setItem('enableClaimSubmission',user.enableClaimSubmission)
      this.loggedin = false
      // this.currentUser.next(user);
      this.router.navigate(['/home']);
    }, error => {
      Swal.close();
      this.loggedin = false
      Swal.fire('', error.error ? error.error.message : error.message ? error.message : error, 'info');
      }
  );
}

logoutService() {
  if(!this.currentUserValue.id) {
    // this.currentUser = new BehaviorSubject<any>(null);
    // localStorage.removeItem('current_user_value');
  }
  let params = new HttpParams();
  params = params.append('id', this.currentUserValue.id);
  const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    params: params
  };
  return this.http.get(environment.apiBaseUrl + '/user/provider-logout', httpOptions).subscribe(
    data => {
      // this.currentUser = new BehaviorSubject<any>(null);
      localStorage.removeItem('current_user_value');
      localStorage.removeItem('id');
      localStorage.removeItem('enableClaimSubmission');
      this.router.navigateByUrl("/login");
    }, error => {
      Swal.fire('Oops...', error.error.message, 'error')
    }
  );
}

generateOTP(email: any) {
  const userId = this.encrypt.generateEncryption(email);
  let params = new HttpParams().set('emailId',  encodeURIComponent(userId));
  params = params.append('portal', environment.loginPortal);
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8'
    }),
    params: params
  };
  return this.http.get(environment.apiBaseUrl + '/user/provider-generate-otp', httpOptions);
}

resetPassword(data: any) {
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8'
    })
  };
  return this.http.post(environment.apiBaseUrl + '/user/passwordReset', data, httpOptions);
}

passwordChange(data: any) {
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=UTF-8'
    })
  };
  return this.http.post(environment.apiBaseUrl + '/user/passwordChange', data, httpOptions);
}


}
