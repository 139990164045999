import { AuthGuardGuard } from './core/guard/auth-guard.guard';
import { MobileAuthGuardGuard } from './core/guard/mobile-auth-guard.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'public',
    loadChildren: () => import('./pages/public/public.module').then(m => m.PublicModule),
    canActivate:[MobileAuthGuardGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'claims',
    loadChildren: () => import('./pages/claims/claims.module').then(m => m.ClaimsModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then(m => m.PaymentsModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'tariff',
    loadChildren: () => import('./pages/tariff/tariff.module').then(m => m.TariffModule),
    canActivate:[AuthGuardGuard]
  },
  {
    path: 'shared',
    loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule),
    canActivate:[AuthGuardGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
