<div class="container">
  <h3 mat-dialog-title class="text-center" style="color: red">Session Inactive</h3>
  <h2 mat-dialog-title class="text-center"> Your session will Logout if no action taken.</h2>
<div class="row">
  <div class="col-md-2"></div>
  <div class="col-md-6">
    <img style="height: 40vh;margin-left: 22px;" src="assets/images/session-expired.gif">
  </div>
  <!-- <div class="col-md-3"></div> -->
</div>
  <div class="row">
    <button
      style="background-color: #4CAF50; color: white;font-size: 16px; padding: 10px 20px;cursor: pointer;transition: background-color 0.3s;border-radius: 5px;"
      class="col m-2" mat-raised-button color="primary" (click)="onNoClick()">Still Active</button>
    <button class="col m-2"
      style="background-color: #f44336;  color: white; font-size: 16px;cursor: pointer;border-radius: 5px;  transition: background-color 0.3s;"
      mat-raised-button color="warn" align="end" (click)="logout()">Logout</button>
  </div>
</div>